import React from "react";
import { Pet } from "../../../common/types";
import { petSvgMap } from "../../helpers/helpers";

interface ProfileProps {
    totals: {
      totalPets: number;
      totalRewards: number;
      totalScore: number;
      totalFpOwed: number;
      totalFpPerSecond: number;
      totalStars: number;
      totalMonsters: number;
    };
    totalCarrots: number | string;
    totalRewards: number | string;
    totalRewardsInUsd: number | string;
    totalEthBalInUsd: number | string;
    fpBalance: number | string;
    fpBalanceInUsd: number | string;
    totalStakedFp: number | string;
    totalStakedFpInUsd: number | string;
    pGoldBalanceFloat: number | string;
    totalPGoldBalInFp: number | string;
    totalPGoldBalInUsd: number | string;
    ethBalance: number | string;
    walletAddress: string;
    speciesCounts: { species: string; count: number }[] | null;
    pets: Pet[];
  }
  

export const Profile: React.FC<ProfileProps> = ({
  totals,
  totalCarrots,
  totalRewards,
  totalEthBalInUsd,
  fpBalance,
  fpBalanceInUsd,
  totalStakedFp,
  totalStakedFpInUsd,
  pGoldBalanceFloat,
  totalPGoldBalInFp,
  totalPGoldBalInUsd,
  ethBalance,
  walletAddress,
  totalRewardsInUsd,
  speciesCounts,
  pets
}) => {
  const shortenWalletAddress = (address: string) => {
        if (address.length > 12) {
            return `${address.slice(0, 6)}...${address.slice(-6)}`;
        }
        return address;
        };
  const highestScoringPet = pets.length > 0 
    ? pets.reduce((prev, current) => (prev.score > current.score ? prev : current)) 
    : null;

  const getPetTypeCounts = (pets: Pet[]) => {
    return pets.reduce((acc: Record<string, number>, pet) => {
      acc[pet.petType] = (acc[pet.petType] || 0) + 1;
      return acc;
    }, {});
  };
  
  const petTypeCounts = getPetTypeCounts(pets);
   
  return (
    <div className="mx-auto mb-8 flex w-screen flex-row flex-wrap justify-center overflow-x-hidden">
      <div className=" text-brown">
        <div className="flex flex-row">
            {highestScoringPet && highestScoringPet.encodedSvgUrl && (
            <a href={`https://frenpet.xyz/pet/${highestScoringPet.id}`} target="_blank" rel="noreferrer">
                <div className="ml-4 h-16 w-16 overflow-hidden rounded-md bg-green relative">
                <img
                    src={highestScoringPet.encodedSvgUrl}
                    alt="Top Pet"
                    className="object-cover h-[4.5rem] w-[4.5rem] ml-[.1rem]"
                />
                </div>
            </a>
            )}
            <h1 className="text-xl font-semibold mt-4 ml-10 mb-4">{shortenWalletAddress(walletAddress)}</h1>
        </div>
        <div className="flex flex-col items-center space-y-2">
        <div className="mb-2 ml-4 mr-4 mt-2  flex h-[27.75rem] w-[21.5rem] flex-col rounded-lg border-2 border-brown bg-white p-3 [box-shadow:inset_0_-2px_#342e2e]">
            <div className="font-semibold text-xl ml-24">Wallet Funds</div>
            <div className="flex flex-row">
                <div className="mb-2 ml-4 mt-2 flex h-[6.75rem] w-[11rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className="text-xl">Rewards</div>
                    <div className="mr-1 flex flex-row"> 
                    <img
                        src="/imgs/treasure.svg"
                        alt=""
                        className="h-[1.6rem] w-[1.6rem] mt-[.15rem]"
                    /> <div className="flex flex-row ml-1 text-xl mt-[.1rem]">{parseFloat(totalRewards.toString()).toFixed(3)} 
                       <div className="text-sm mt-[.3rem] ml-[.15rem]"> ETH </div>
                        </div>
                    </div>
                    <div className="mr-1 text-darkgrey flex flex-row"> (${totalRewardsInUsd}) </div>
                </div>
                <div className="mb-2 ml-4 mr-2 mt-2 flex h-[6.75rem] w-[8.5rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className=" text-xl">ETH</div>
                    <div className="mr-1 flex flex-row"> 
                    <img
                        src="/imgs/wallet.svg"
                        alt=""
                        className="h-[1.25rem] w-[1.25rem] mt-[.3rem]"
                    /> <div className="ml-1 text-xl mt-[.1rem]">{parseFloat(ethBalance.toString()).toFixed(3)}</div>
                    </div>
                    <div className="mr-1 text-darkgrey flex flex-row"> (${totalEthBalInUsd}) </div>
                </div>
            </div>
            <div className="flex flex-row">
                <div className="mb-2 ml-4 mt-2 flex h-[6.75rem] w-[10rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className="text-xl">Staked FP</div>
                    <div className="mr-1 flex flex-row"> 
                    <img
                        src="/imgs/frog.svg"
                        alt=""
                        className="h-[1.35rem] w-[1.35rem] mt-[.25rem]"
                    /> <div className="ml-1 text-xl mt-[.1rem]">{totalStakedFp} </div>
                    </div>
                    <div className="mr-1 text-darkgrey flex flex-row"> (${totalStakedFpInUsd}) </div>
                </div>
                <div className="mb-2 ml-4 mr-2 mt-2 flex h-[6.75rem] w-[10rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className=" text-xl">FP</div>
                    <div className="mr-1 flex flex-row"> 
                    <img
                        src="/imgs/frog.svg"
                        alt=""
                        className="h-[1.35rem] w-[1.35rem] mt-[.25rem]"
                    /> <div className="ml-1 text-xl mt-[.1rem]">{parseFloat(fpBalance.toString()).toFixed(1)} </div>
                    </div>
                    <div className="mr-1 text-darkgrey flex flex-row"> (${fpBalanceInUsd}) </div>
                </div>
            </div>
            <div className="flex flex-row">
                <div className="mb-2 ml-4 mr-2 mt-2 flex h-[8rem] w-[18.5rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className=" text-xl">pGold</div>
                    <div className="mr-1 flex flex-row"> 
                    <img
                        src="/imgs/coin-bag.svg"
                        alt=""
                        className="h-[1.35rem] w-[1.35rem] mt-[.25rem]"
                    /> <div className="ml-1 text-xl mt-[.1rem]">{pGoldBalanceFloat} </div>
                    </div>
                    <div className="mr-1 text-darkgrey flex flex-row"> ({totalPGoldBalInFp} FP) </div>
                    <div className="mr-1 text-darkgrey flex flex-row"> (${totalPGoldBalInUsd}) </div>
                </div>
            </div>
      </div> 
          <div className="mb-2 ml-4 mr-4 mt-2  flex h-[35rem] w-[21.5rem] flex-col rounded-lg border-2 border-brown bg-white p-3 [box-shadow:inset_0_-2px_#342e2e]">
          <div className="font-semibold text-xl ml-24">Pet Information</div>
            <div className="flex flex-row">
                <div className="mb-2 ml-4 items-center mr-2 mt-2 flex h-[5.75rem] w-[7.9rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className="text-xl">Pets</div>
                    <div className="mr-2 flex flex-row"> 
                    <img
                        src="/imgs/paw.svg"
                        alt=""
                        className="h-6 w-6 mt-[.2rem]"
                    /> <div className="ml-1 text-xl mt-[.1rem]">{totals.totalPets} </div>
                    </div>
                </div>
                <div className="mb-2 ml-2 mr-4 items-center mt-2 flex h-[5.75rem] w-[11rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className="text-xl">Carrots</div>
                    <div className=" mr-2 flex flex-row"> 
                        <img
                            src="/imgs/carrot.svg"
                            alt=""
                            className="h-6 w-6 mt-[.2rem]"
                        /> <div className="ml-1 text-xl mt-[.1rem]">{parseFloat(totalCarrots.toString()).toFixed(0)}</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row">
                <div className="mb-2 ml-4 items-center mr-2 mt-2 flex h-[5rem] w-[18.5rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className="text-xl">Score</div>
                    <div className="mr-2 flex flex-row"> 
                    <img
                        src="/imgs/coin-bag.svg"
                        alt=""
                        className="h-[1.35rem] w-[1.35rem] mt-[.25rem]"
                    /> <div className="ml-2 text-xl mt-[.1rem]">{parseFloat(totals.totalScore.toString()).toFixed(0)} </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row">
                <div className="mb-2 ml-4 items-center mr-4 mt-2 flex h-auto w-[21.5rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className="text-xl mb-2">Pet Types</div>
                    <div className="flex flex-wrap justify-center gap-x-8 gap-y-4">
                    {Object.keys(petSvgMap)
                        .sort((typeA, typeB) => {
                        const countA = speciesCounts?.find((s) => s.species === typeA)?.count || 0;
                        const countB = speciesCounts?.find((s) => s.species === typeB)?.count || 0;
                        return countB - countA;
                        })
                        .map((type) => {
                        const count = petTypeCounts[type] || 0;
                        return (
                            <div key={type} className="flex items-center space-x-2">
                            {petSvgMap[type] ? (
                                <img src={petSvgMap[type]} alt={type} className="h-6 w-6" />
                            ) : (
                                <span className="text-lg">{type}</span>
                            )}
                            <span className="text-xl">{count}</span>
                            </div>
                        );
                        })}
                    </div>
                </div>
            </div>
            <div className="flex flex-row">
                <div className="mb-2 mr-1 mt-2 ml-4 items-center flex h-[5.75rem] w-[9.5rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className="text-xl">Monsters</div>
                    <div className="mr-2 flex flex-row"> 
                    <img
                        src="/imgs/pokeball.svg"
                        alt=""
                        className="h-6 w-6 mt-[.25rem]"
                    /> <div className="ml-1 text-xl mt-[.1rem]">{totals.totalMonsters} </div>
                    </div>
                </div>
                <div className="mb-2 ml-2 mr-4 mt-2 items-center  flex h-[5.75rem] w-[9.5rem] flex-col rounded-lg border-2 border-brown bg-grey p-3 [box-shadow:inset_0_-2px_#342e2e]">
                    <div className="text-xl">Stars</div>
                    <div className="mr-2 flex flex-row"> 
                    <img
                        src="/imgs/star.svg"
                        alt=""
                        className="h-6 w-6 mt-[.2rem]"
                    /> <div className="ml-1 text-xl mt-[.1rem]">{totals.totalStars} </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
