
export const Changelog = () => (
    <div className="flex flex-col items-center justify-left h-full w-full">
      <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Jan - 19</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/profile.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>add profile viewer</p>
            </li>   
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/chart.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>unlock monthly view for treasury chart</p>
            </li>   
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/ethereum.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>add prices in header to relevant views</p>
            </li>   
          </ul>
          </div> 
        </div>
       <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Dec - 19</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/ethereum.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>add ETH gas price in stat bar</p>
            </li>   
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/chart.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>unlock weekly view for treasury chart</p>
            </li>   
          </ul>
          </div> 
        </div>
        <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Nov - 29</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/passport.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>stats in pet passes view pet images</p>
            </li>   
          </ul>
          </div> 
        </div>
        <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Nov - 27</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
          <li className="flex items-center mb-2 ">
              <img
                src="/imgs/paw.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>blend hack for pepe contract svgs</p>
            </li>  
          <li className="flex items-center mb-2 ">
              <img
                src="/imgs/paw.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>normalize enlarged contract svgs</p>
            </li>   
          <li className="flex items-center mb-2 ">
              <img
                src="/imgs/chart.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.25rem] mr-1">•</div>
              <p>chart axis fixes</p>
            </li> 
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/passport.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>pet passes view</p>
            </li>   
          </ul>
          </div> 
        </div>
      <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Nov - 26</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/chart.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.25rem] mr-1">•</div>
              <p>treasury chart</p>
            </li> 
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/chart.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.25rem] mr-1">•</div>
              <p>fix certain chart tooltips</p>
            </li> 
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/chart.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.25rem] mr-1">•</div>
              <p>unlock weekly view for pgold balance chart</p>
            </li> 
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/rank.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.25rem] mr-1">•</div>
              <p>add species count to leaderboards</p>
            </li> 
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/rank.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.25rem] mr-1">•</div>
              <p>fix leaderboards intraweek sort</p>
            </li>   
          </ul>
          </div> 
      </div>
      <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Nov - 22</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/gas.svg"
                alt=""
                className="ml-[.1rem] h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>USD value per transaction</p>
            </li>   
          </ul>
          </div> 
      </div>
      <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Nov - 19</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                 src="/imgs/chart.svg"
                 alt=""
                 className="mt-[0.185rem] mr-1 h-4 w-4 "
              />
              <div className="ml-[.02rem] mr-1">•</div>
              <p>pgold balance in charts</p>
            </li>
            <li className="flex items-center mb-2 ">
              <img
                 src="/imgs/hibernated.svg"
                 alt=""
                 className="mt-[0.185rem] mr-1 h-4 w-4 "
              />
              <div className="ml-[.02rem] mr-1">•</div>
              <p>hibernated badge</p>
            </li>
            <li className="flex items-center mb-2 ">
              <img
                 src="/imgs/passBadge.png"
                 alt=""
                 className="mt-[0.185rem] mr-1 h-4 w-4 "
              />
              <div className="ml-[.02rem] mr-1">•</div>
              <p>moved non-player related charts to stats</p>
            </li>
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/rank.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.25rem] mr-1">•</div>
              <p>leaderboards refresh daily, update weekly</p>
            </li>   
            <li className="flex items-center mb-2 ">
              <img
                 src="/imgs/passBadge.png"
                 alt=""
                 className="mt-[0.1rem] mr-1 h-4 w-4 "
              />
              <div className="ml-[.02rem] mr-1">•</div>
              <p>change pets alive data to update weekly</p>
            </li> 
          </ul>
          </div> 
      </div>
      <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Nov - 15</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/paw.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.1rem] mr-1">•</div>
              <p>top 100 leaderboards for every species</p>
            </li>   
            <li className="flex items-center mb-2">
             <img src="/imgs/gear.svg" alt="" className="h-4 w-4 mt-[.185rem]" />
             <div className="ml-[.1rem] mr-1">•</div>
             <p>removed attack and defense leaderboards</p>
            </li>
            <li className="flex items-center mb-2">
              <img
                src="/imgs/frog.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>staked FP behind eth in shop/leaderboards</p>
            </li>
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/compass.svg"
                alt=""
                className="h-4 w-4 mt-[.185rem]"
              />
              <div className="ml-[.15rem] mr-1">•</div>
              <p>pet EP in shop/leaderboards</p>
            </li> 
          </ul>
          </div> 
        </div>
      <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Nov - 12</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/star.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.15rem] mr-1">•</div>
              <p>top 100 keeper leaderboard added</p>
            </li>   
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/compass.svg"
                alt=""
                className="h-4 w-4 mt-[.185rem]"
              />
              <div className="ml-[.15rem] mr-1">•</div>
              <p>available quests badge</p>
            </li> 
          </ul>
          </div> 
      </div>
      <div className="flex flex-col items-start justify-start mt-2 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
        <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Nov - 11</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/bolt.svg"
                alt=""
                className="h-5 w-5"
              />
              <div className="ml-[.02rem] mr-1">•</div>
              <p>click bolt to see remaining bonk count</p>
            </li>
            <li className="flex items-center mb-2">
              <img
                src="/imgs/passBadge.png"
                alt=""
                className="ml-[.05rem] h-4 w-4 mt-[.185rem]"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>pass badge indicating pet has FP Pass</p>
            </li>
            <li className="flex items-center mb-2">
              <img
                src="/imgs/frog.svg"
                alt=""
                className="h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>frog badge indicating staking perks active</p>
            </li>
            <li className="flex items-center mb-2">
                <img
                    src="/imgs/coin-bag.svg"
                    alt=""
                    className="h-4 w-4"
               />
               <div className="ml-1 mr-1">•</div>
              <p>pGold chart updated with monthly view</p>
            </li>
            <li className="flex items-center mb-2">
             <img
                src="/imgs/ethereum.svg"
                alt=""
                className="h-4 w-4"
             />
             <div className="ml-1 mr-1">•</div>
             <p>click ETH symbol on pet stat card to see FP </p>
            </li>
            <li className="flex items-center mb-2">
             <img src="/imgs/compass.svg" alt="" className="h-4 w-4 mt-[.185rem]" />
             <div className="ml-1 mr-1">•</div>
             <p>pet EP updated daily</p>
            </li>
            <li className="flex items-center mb-2">
             <img src="/imgs/gear.svg" alt="" className="h-4 w-4 mt-[.185rem]" />
             <div className="ml-1 mr-1">•</div>
             <p>sort by monsters, pass holder, EP added</p>
            </li>
            <li className="flex items-center mb-2">
             <img
                src="/imgs/passBadge.png"
                alt=""
                className="mt-[0.185rem] mr-1 h-4 w-4 "
             />
             <div className="mr-1">•</div>
             <p>stats view added with pet species chart</p>
            </li>  
            <li className="flex items-center mb-2">
             <img
                src="/imgs/clipboard.svg"
                alt=""
                className="mt-[0.05rem] mr-1 h-4 w-4"
             />
             <div className="mr-1">•</div>
             <p>change log view added</p>
            </li>     
          </ul>
        </div>
        {/* 
        <div className="flex flex-col items-start justify-start mt-2 mb-3 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
         <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Nov - 11</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/bolt.svg"
                alt=""
                className="h-5 w-5"
              />
              <div className="ml-[.02rem] mr-1">•</div>
              <p>click bolt to see remaining bonk count</p>
            </li>   
          </ul>
          </div> 
        </div>
        */}
      </div>
      
    </div>
  );