import { TreasuryBalance } from "../../../common/types";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  LineChart,
  Tooltip,
} from "recharts";
import { useState } from "react";
import { ChartTooltip, secondsDict } from "../utils/ChartUtils";
import { formatDayTime, calculateTicks, formatCurrency } from "../../helpers/helpers";

export function Button({
  text,
  selected,
  setSelected,
}: {
  text: string;
  selected: boolean;
  setSelected: (text: string) => void;
}) {
  return (
    <button
      className={`rounded-lg border-[1px] border-brown px-2 py-1 ${
        selected ? "bg-brown text-white" : "bg-white text-brown"
      }`}
      onClick={() => setSelected(text)}
    >
      {text}
    </button>
  );
}

export function TreasuryChart({
  treasuryBalances,
}: {
  treasuryBalances: TreasuryBalance[];
}) {
  const [selected, setSelected] = useState("1D");

  const data = treasuryBalances.filter(
    (balance) => balance.timestamp > Date.now() / 1000 - secondsDict[selected]
  );

  return (
    <div className="my-2 ml-4 mr-4 flex w-[80%] flex-col items-start justify-center rounded-lg border-2 border-brown bg-white p-4 text-sm [box-shadow:inset_0_-2px_#342e2e]">
      <div className="flex w-full justify-between">
        <div className="mb-4 font-sans text-xl">Treasury</div>
        <div className="mb-4 space-x-1 font-sans text-sm">
          <Button text="1D" selected={selected === "1D"} setSelected={setSelected} />
          <Button text="7D" selected={selected === "7D"} setSelected={setSelected} />
          <Button text="1M" selected={selected === "1M"} setSelected={setSelected} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}  margin={{ left: 30 }}>
          <XAxis
            dataKey="timestamp"
            type="number"
            tickFormatter={formatDayTime}
            ticks={calculateTicks(data)}
            domain={["dataMin", "dataMax"]}
            stroke="#342e2e"
          />
          <YAxis
            dataKey="treasury_balance"
            stroke="#342e2e"
            domain={["auto", "auto"]}
            tickFormatter={formatCurrency}
          />
          <Line
            type="monotone"
            dataKey="treasury_balance"
            stroke="#342e2e"
            dot={false}
          />
          <Tooltip content={<ChartTooltip unit="FP" valueDecimals={2} />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
